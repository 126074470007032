import { graphql, StaticQuery } from 'gatsby'
import PropTypes from 'prop-types'
import React, { useContext } from 'react'
import Helmet from 'react-helmet'

import { DarkModeContext } from '../context/DarkModeContext'

const detailsQuery = graphql`
  query SeoQuery {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      title
      domain
      description
      keywords
      author
    }
    charis: sanityPerson(name: {regex: "/Leonidas/"}) {
      name
      twitter
      image {
        asset {
          url
        }
      }
    }
  }
`

function Seo ({
  description,
  lang,
  meta,
  keywords = [],
  title,
  url = '',
  image,
  articleBody,
  publishedAt,
  updatedAt,
  author
}) {
  const { darkMode } = useContext(DarkModeContext)

  return (
    <StaticQuery
      query={detailsQuery}
      render={data => {
        if (!data.site) {
          return
        }
        const metaDescription = description || data.site.description
        const pageImage = image || (author && author.image.asset.url) || data.charis.image.asset.url
        const authorName = (author && author.name) || data.charis.name
        const pageUrl = data.site.domain + url
        const pageKeywords = keywords && keywords.length > 0 ? keywords.join(', ') : data.site.keywords.join(', ')
        const isArticle = !!publishedAt || !!articleBody

        return (
          <Helmet
            htmlAttributes={{
              lang,
              class: darkMode ? 'dark' : 'light'
            }}
            script={[{
              type: 'application/ld+json',
              // ? Structured data testing tool: https://search.google.com/u/1/test/rich-results
              innerHTML: JSON.stringify({
                '@context': 'https://schema.org',
                '@type': isArticle ? 'TechArticle' : 'WebPage',
                description: metaDescription,
                headline: title,
                proficiencyLevel: isArticle ? 'Beginner' : '',
                alternativeHeadline: metaDescription,
                image: pageImage,
                author: authorName,
                editor: authorName,
                keywords: pageKeywords,
                url: pageUrl,
                datePublished: publishedAt,
                dateCreated: publishedAt,
                dateModified: updatedAt,
                articleBody: articleBody,
                publisher: {
                  '@id': data.site.domain,
                  '@type': 'Person',
                  name: authorName,
                  image: (author && author.image.asset.url) || data.charis.image.asset.url
                },
                mainEntityOfPage: isArticle ? {
                  '@type': 'WebPage',
                  '@id': pageUrl
                } : ''
              })
            }]}
            title={title}
            titleTemplate={title === data.site.title ? '%s' : `%s | ${data.site.title}`}
            meta={[
              {
                name: 'description',
                content: metaDescription
              },
              {
                property: 'og:site_name',
                content: data.site.title
              },
              {
                property: 'og:title',
                content: title
              },
              {
                property: 'og:url',
                content: pageUrl
              },
              {
                property: 'og:description',
                content: metaDescription
              },
              {
                property: 'og:type',
                content: isArticle ? 'article' : 'website'
              },
              {
                property: 'og:image',
                content: pageImage
              },
              {
                name: 'twitter:image',
                content: pageImage
              },
              {
                name: 'twitter:card',
                content: 'summary'
              },
              {
                name: 'twitter:site',
                content: data.charis.twitter
              },
              {
                name: 'twitter:creator',
                content: `@${(author && author.twitter) || data.charis.twitter}`
              },
              {
                name: 'twitter:title',
                content: title
              },
              {
                name: 'twitter:description',
                content: metaDescription
              }
            ]
              .concat({
                name: 'keywords',
                content: pageKeywords
              })
              .concat(meta)}
          />
        )
      }}
    />
  )
}

Seo.defaultProps = {
  lang: 'en',
  meta: [],
  keywords: [],
  url: ''
}

Seo.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.array,
  keywords: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string.isRequired,
  url: PropTypes.string
}

export default Seo
