import { graphql, Link, StaticQuery } from 'gatsby'
import React from 'react'
import { FaRss } from 'react-icons/fa'

import { getSocialName, getSocialUrl } from '../lib/helpers'
import { getSocialIcon } from '../lib/socialIcons'
import OutboundLink from './block-content/outboundLink'
import styles from './socialList.module.css'

const SocialList = () => {
  return (
    <StaticQuery
      query={personSocialQuery}
      render={data => {
        const {
          github,
          linkedIn,
          medium,
          stackOverflow,
          twitter
        } = data.social

        const socials = [{ github }, { linkedIn }, { medium }, { stackOverflow }, { twitter }]
          .filter(s => !!s)

        return (
          <ul className={styles.socialList}>
            {socials.map((social, i) => (
              <li key={`social-${i}`} className={styles.socialListItem}>
                <OutboundLink
                  className={styles.socialLink}
                  href={getSocialUrl(social)}
                  title={`Visit my profile on ${getSocialName(social)}`}
                >
                  {getSocialIcon(social)()}
                </OutboundLink>
              </li>
            ))}
            <li key='rss' className={styles.socialListItem}>
              <Link className={styles.socialLink} to='/rss.xml' title='Subscribe to my RSS feed'>
                <FaRss />
              </Link>
            </li>
          </ul>
        )
      }}
    />
  )
}

export default SocialList

const personSocialQuery = graphql`
  query PersonSocialQuery {
    social: sanityPerson(name: {regex: "/Leonidas/"}) {
      twitter
      github
      stackOverflow
      name
      medium
      linkedIn
    }
  }
`
