import { Link } from 'gatsby'
import React from 'react'

import { cn } from '../lib/helpers'
import styles from './nav.module.css'
import { large } from './typography.module.css'

const Nav = ({ showNav }) => {
  return (
    <nav className={cn(styles.nav, showNav && styles.showNav, large)}>
      <ul>
        <li>
          <Link to='/'><em>Home</em></Link>
        </li>
        <li>
          <Link to='/blog/'><em>Blog</em></Link>
        </li>
        {/* <li>
          <Link to='/lazy-hacks/'><em>Lazy hacks</em></Link>
        </li> */}
        <li>
          <Link to='/projects/'><em>Projects</em></Link>
        </li>
        <li>
          <Link to='/about/'><em>About</em></Link>
        </li>
        <li>
          <Link to='/contact/'><em>Contact me</em></Link>
        </li>
      </ul>
    </nav>
  )
}

export default Nav
