import { format } from 'date-fns'

export function isTouchDevice () {
  if (typeof window === 'undefined') {
    return false
  }
  return (
    ('ontouchstart' in window) ||
    (navigator.maxTouchPoints > 0) ||
    (navigator.msMaxTouchPoints > 0)
  )
}

export function cn (...args) {
  return args.filter(Boolean).join(' ')
}

export function mapEdgesToNodes (data) {
  if (!data.edges) return []
  return data.edges.map(edge => edge.node)
}

export function filterOutDocsWithoutSlugs ({ slug }) {
  return (slug || {}).current
}

export function getUrlForType (publishedAt, slug, type) {
  switch (type) {
    case 'blog':
    case 'post':
      return getBlogPostUrl(publishedAt, slug)
    case 'lazy-hacks':
    case 'lazyhack':
      return getLazyHackUrl(publishedAt, slug)
    case 'projects':
    case 'project':
      return getProjectUrl(slug)
    default:
      return `/${slug.current || slug}/`
  }
}

export function getBlogPostUrl (publishedAt, slug) {
  return `/blog/${format(publishedAt, 'YYYY/MM')}/${slug.current || slug}/`
}

export function getLazyHackUrl (publishedAt, slug) {
  return `/lazy-hacks/${format(publishedAt, 'YYYY/MM')}/${slug.current || slug}/`
}

export function getProjectUrl (slug) {
  return `/project/${slug.current || slug}/`
}

export function getStringFromBlocks (blocks = []) {
  if (!blocks) {
    // * When passing `null` into the function, i.e.  getStringFromBlocks(null)
    // * the default value of `[]` is not assigned to the `blocks` variable
    return ''
  }
  return blocks.reduce((acc, cur) => acc + ' ' + (cur.children ? cur.children.reduce((acc, cur) => acc + cur.text, '') : ''), '')
}

export function getStringArrayFromCategories (categories) {
  return categories ? categories.map(c => c.title) : []
}

export function getImageSizeFromRef (ref) {
  const dimensionsString = /(\d+)x(\d+)/g.exec(ref)
  const width = dimensionsString[1]
  const height = dimensionsString[2]
  const dimensions = dimensionsString[0]
  const aspectRatio = height / width

  return {
    dimensions,
    width,
    height,
    aspectRatio
  }
}

/**
 * Note: All article images have `max-height: 600px`
 * Note: 100% for padding, it taking the 100% value of its parent width,
 * the parent element has also margin and padding of `2rem` and `--main-content-padding` respectively
 *
 * @param {Number} aspectRatio
 * @returns {String} - a CSS value
 */
export function getPaddingFromAspectRatio (aspectRatio) {
  return aspectRatio > 1
    ? 'min(calc(' + aspectRatio * 100 + '% - (2 * var(--main-content-padding)) - 2rem), 600px)'
    : Math.min(aspectRatio * 100, 100) + '%'
}

export function buildImageObj (source) {
  const imageObj = {
    asset: { _ref: source.asset._ref || source.asset._id }
  }

  if (source.crop) imageObj.crop = source.crop
  if (source.hotspot) imageObj.hotspot = source.hotspot

  return imageObj
}

export function normaliseCodeLanguage (language) {
  switch (language) {
    case 'sh':
      return 'bash'
    default:
      return language
  }
}

export function getHighlightedLinesRange (lines) {
  if (lines && !!lines.length) {
    const sortedLines = lines.sort((a, b) => a - b)
    lines = sortedLines.reduce((acc, cur, i) => {
      const previous = sortedLines[i - 1]
      const next = sortedLines[i + 1]

      if (cur === previous + 1) {
        if (cur === next - 1) {
          return acc === '' ? cur : acc
        } else {
          return `${acc}-${cur}`
        }
      }

      return acc === '' ? cur : `${acc},${cur}`
    }, '')

    return lines
  }
}

export function getBlockQuoteType (blockQuote) {
  let firstWords = ''
  try {
    firstWords = blockQuote.children[0].trim().toLowerCase()
  } catch (error) {
    firstWords = blockQuote.children[0].props.node.children[0].trim().toLowerCase()
  }

  if (firstWords.indexOf('note') === 0) {
    return 'note'
  }
  if (firstWords.indexOf('warning') === 0) {
    return 'warning'
  }
  if (firstWords.indexOf('best practise') === 0) {
    return 'bestPractise'
  }
  if (firstWords.indexOf('tip') === 0) {
    return 'tip'
  }
  return ''
}

export function getHeadingId (blockProps) {
  const textNodes = blockProps.node.children.map(_ => _.text
    .replace(/[^A-Za-z0-9\s]/g, '')
    .split(' ')
    .join('-')
    .toLowerCase()
  )
  return textNodes.join('-')
}

export function getSocialName (social) {
  return Object.keys(social)[0].trim().charAt(0).toUpperCase() + Object.keys(social)[0].slice(1)
}

export function getSocialUrl (social) {
  const socialName = Object.keys(social)[0]
  switch (socialName) {
    case 'github':
      return `https://github.com/${social[socialName]}`
    case 'linkedIn':
      return `https://www.linkedin.com/in/${social[socialName]}`
    case 'medium':
      return `https://medium.com/@${social[socialName]}`
    case 'stackOverflow':
      return `https://stackoverflow.com/story/${social[socialName]}`
    case 'twitter':
      return `https://twitter.com/${social[socialName]}`
    default:
      return '/'
  }
}

export function copyToClipboard (text) {
  if (typeof window === 'undefined') {
    return false
  }
  const el = document.createElement('textarea')
  el.value = text
  document.body.appendChild(el)
  el.select()
  document.execCommand('copy')
  document.body.removeChild(el)
  return true
}
