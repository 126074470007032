import React, { useEffect, useState } from 'react'
import { FaRss } from 'react-icons/fa'

import { cn } from '../lib/helpers'
// import OutboundLink from './block-content/outboundLink'
import styles from './header.module.css'
import Icon from './icons'
import Nav from './nav'

const Header = ({ onHideNav, onShowNav, showNav }) => {
  const [blurNavBackground, setBlurNavBackground] = useState(false)

  useEffect(() => {
    const pageWrapper = document.querySelector('#gatsby-focus-wrapper')
    // * Detect if browser supports passive event listeners
    // * Learn more: https://github.com/WICG/EventListenerOptions/blob/gh-pages/explainer.md#feature-detection
    var supportsPassive = false
    try {
      /* eslint-disable getter-return */
      var opts = Object.defineProperty({}, 'passive', { get: function () { supportsPassive = true } })
      window.addEventListener('testPassive', null, opts)
      window.removeEventListener('testPassive', null, opts)
    } catch (e) { }

    const scrollEventListener = () => {
      if (pageWrapper.scrollTop > 50 && !blurNavBackground) {
        setBlurNavBackground(true)
      } else if (pageWrapper.scrollTop <= 50 && blurNavBackground) {
        setBlurNavBackground(false)
      }
    }

    pageWrapper.addEventListener('scroll', scrollEventListener, supportsPassive ? { passive: true } : false)
    // * Fixes multiple event listeners being added on each Gatsby navigation
    // ? Only in Chrome Dev Tools: window.getEventListeners(pageWrapper)["scroll"]
    // * returned function will run before the component unmounts (similar to `componentWillUnmount`)
    return function cleanup () {
      pageWrapper.removeEventListener('scroll', scrollEventListener)
    }
  })

  return (
    <header className={cn(styles.root, blurNavBackground && styles.blurBackground)}>
      <div className={styles.wrapper}>
        <button
          title='Toggle navigation menu'
          className={styles.toggleNavButton}
          onClick={showNav ? onHideNav : onShowNav}
        >
          {showNav
            ? <Icon symbol='close' />
            : <Icon symbol='hamburger' />}
        </button>

        <div className={styles.branding}>
          {/* Don't use Gatsby Link to prevent prefetching calls to this route */}
          {/* <img src="../../favicons/logo-200.png"/> */}
          <img src={require("../favicons/logo-200.png")} width="70" height="70" />
          <a href='/rss.xml' title='Subscribe to my RSS feed'>
            <FaRss size={22} />
          </a>
          {/*
          <OutboundLink
            href='https://twitter.com/charisTheo_dev?ref_src=twsrc%5Etfw'
            className='twitter-follow-button'
            data-show-count='false'
            target='_blank'
          >
            Follow me on twitter
          </OutboundLink> */}
        </div>

        <Nav showNav={showNav} />
      </div>
    </header>
  )
}

export default Header
