// ? Search for icons: https://react-icons.github.io/react-icons/search
import {
  FaGithub,
  FaLinkedinIn,
  FaMediumM,
  FaStackOverflow,
  FaTwitter
} from 'react-icons/fa'

export function getSocialIcon(social) {
  const socialName = Object.keys(social)[0]
  switch (socialName) {
    case 'github':
      return FaGithub
    case 'linkedIn':
      return FaLinkedinIn
    case 'medium':
      return FaMediumM
    case 'stackOverflow':
      return FaStackOverflow
    case 'twitter':
      return FaTwitter
    default:
      return () => { }
  }
}
